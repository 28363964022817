.blogDetails {
  margin-top: 30px;
  margin-bottom: 30px;
  border: 1px solid rgb(236, 236, 236);

  .hre {
    margin-top: 15px;
    display: inline-block;
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .image {
    width: 100%;
    height: 300px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 15px;
  }
  .tags {
    margin: 20px 0;
    span {
      padding: 2px 12px;
      border-radius: 3px;
      color: white;
      background-color: #007bff;
      display: inline-block;
      margin-bottom: 3px;
      margin-right: 3px;
      font-size: 12px;
    }
  }

  .time {
    margin: 20px 0;
    text-align: right;
    font-size: 13px;
  }
  .title {
    font-weight: 900;
    font-size: 22px;
    letter-spacing: 2px;
  }
  .htm {
    margin-top: 30px;
  }
}
